/* eslint-disable no-trailing-spaces, eol-last */
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    /* Colors */
    --color-background: #ffffff;
    --color-text: #1a1a1a;
    --color-primary: #007AFF;
    --color-secondary: #5856D6;
    --color-accent: #FF2D55;
    --color-gray-100: #f7f7f7;
    --color-gray-200: #e6e6e6;
    --color-gray-300: #d4d4d4;
    --color-gray-400: #a3a3a3;
    --color-gray-500: #737373;
    --color-gray-600: #525252;
    --color-gray-700: #404040;
    --color-gray-800: #262626;
    --color-gray-900: #171717;
    
    /* Typography */
    --font-sans: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    --font-mono: 'JetBrains Mono', ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
    --font-display: 'Cal Sans', var(--font-sans);
    
    /* Font Sizes */
    --text-xs: clamp(0.75rem, 0.7rem + 0.25vw, 0.875rem);
    --text-sm: clamp(0.875rem, 0.8rem + 0.375vw, 1rem);
    --text-base: clamp(1rem, 0.925rem + 0.375vw, 1.125rem);
    --text-lg: clamp(1.125rem, 1rem + 0.625vw, 1.25rem);
    --text-xl: clamp(1.25rem, 1.125rem + 0.625vw, 1.5rem);
    --text-2xl: clamp(1.5rem, 1.375rem + 0.625vw, 1.875rem);
    --text-3xl: clamp(1.875rem, 1.75rem + 0.625vw, 2.25rem);
    --text-4xl: clamp(2.25rem, 2rem + 1.25vw, 3rem);
    
    /* Spacing */
    --space-1: 0.25rem;
    --space-2: 0.5rem;
    --space-3: 0.75rem;
    --space-4: 1rem;
    --space-5: 1.25rem;
    --space-6: 1.5rem;
    --space-8: 2rem;
    --space-10: 2.5rem;
    --space-12: 3rem;
    --space-16: 4rem;
    --space-20: 5rem;
    --space-24: 6rem;
    
    /* Layout */
    --max-width: 1200px;
    --header-height: 4rem;
    --border-radius-sm: 0.25rem;
    --border-radius: 0.5rem;
    --border-radius-lg: 1rem;
    
    /* Transitions */
    --transition-all: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    --transition-transform: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    --transition-opacity: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    
    /* Shadows */
    --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    scroll-behavior: smooth;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: var(--font-sans);
    font-size: var(--text-base);
    line-height: 1.6;
    color: var(--color-text);
    background-color: var(--color-background);
    overflow-x: hidden;
    padding-top: var(--header-height);
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-display);
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: var(--space-4);
  }

  h1 { font-size: var(--text-4xl); }
  h2 { font-size: var(--text-3xl); }
  h3 { font-size: var(--text-2xl); }
  h4 { font-size: var(--text-xl); }
  h5 { font-size: var(--text-lg); }
  h6 { font-size: var(--text-base); }

  p {
    margin-bottom: var(--space-4);
  }

  a {
    color: var(--color-primary);
    text-decoration: none;
    transition: var(--transition-all);

    &:hover {
      color: var(--color-accent);
    }
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: var(--border-radius);
  }

  button {
    font-family: var(--font-sans);
    cursor: pointer;
    border: none;
    background: none;
    padding: var(--space-2) var(--space-4);
    font-size: var(--text-base);
    border-radius: var(--border-radius);
    transition: var(--transition-all);

    &:focus {
      outline: 2px solid var(--color-primary);
      outline-offset: 2px;
    }
  }

  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: var(--space-2);
    background-color: var(--color-primary);
    color: white;
    font-weight: 500;
    padding: var(--space-3) var(--space-6);
    border-radius: var(--border-radius);
    transition: var(--transition-all);

    &:hover {
      background-color: var(--color-secondary);
      transform: translateY(-1px);
    }

    &:active {
      transform: translateY(0);
    }
  }

  .card {
    background-color: var(--color-gray-100);
    border-radius: var(--border-radius-lg);
    padding: var(--space-6);
    box-shadow: var(--shadow-md);
    transition: var(--transition-all);

    &:hover {
      transform: translateY(-2px);
      box-shadow: var(--shadow-lg);
    }
  }

  .grid {
    display: grid;
    gap: var(--space-6);
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
  }

  .flex {
    display: flex;
    gap: var(--space-4);
  }

  @media (max-width: 768px) {
    :root {
      --header-height: 3.5rem;
    }

    .grid {
      gap: var(--space-4);
    }
  }

  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
`;

export default GlobalStyles; 