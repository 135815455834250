import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const LoadingDot = styled(motion.div)`
  width: 12px;
  height: 12px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primary};
`;

const Loading = () => {
  const { theme } = useTheme();

  return (
    <LoadingContainer>
      {[...Array(3)].map((_, i) => (
        <LoadingDot
          key={i}
          theme={theme}
          animate={{
            scale: [1, 1.2, 1],
            opacity: [1, 0.5, 1],
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
            delay: i * 0.2,
          }}
        />
      ))}
    </LoadingContainer>
  );
};

export default Loading; 