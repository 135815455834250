import React, {
  useEffect,
  Suspense,
} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import ReactGA from 'react-ga4';
import { AnimatePresence } from 'framer-motion';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/jetbrains-mono';
import { ThemeProvider } from './context/ThemeContext';
import GlobalStyles from './styles/GlobalStyles';
import './static/css/main.scss';
import Loading from './components/common/Loading';
import * as serviceWorker from './serviceWorker';

// Initialize ReactGA with your Measurement ID and configuration options
ReactGA.initialize('G-0EHR9GRRG8', {
  testMode: process.env.NODE_ENV !== 'production',
  gaOptions: {
    cookieFlags: 'SameSite=None;Secure',
  },
});

// Register service worker
if (process.env.NODE_ENV === 'production') {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}

const { PUBLIC_URL } = process.env;

// Lazy-loaded components
const About = React.lazy(() => import('./pages/About'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Index = React.lazy(() => import('./pages/Index'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const Projects = React.lazy(() => import('./pages/Projects'));
const Resume = React.lazy(() => import('./pages/Resume'));
const Stats = React.lazy(() => import('./pages/Stats'));

const PageRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    // Track pageview with error handling
    try {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname,
        title: document.title,
      });
    } catch (error) {
      console.error('Analytics error:', error);
    }
  }, [location]);

  return (
    <AnimatePresence mode="wait">
      <Suspense fallback={<Loading />}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
};

const App = () => (
  <ThemeProvider>
    <GlobalStyles />
    <BrowserRouter basename={PUBLIC_URL}>
      <PageRoutes />
    </BrowserRouter>
  </ThemeProvider>
);

export default App;
