import React, { createContext, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const ThemeContext = createContext();

export const themes = {
  light: {
    background: '#ffffff',
    text: '#1a1a1a',
    primary: '#007AFF',
    secondary: '#5856D6',
    accent: '#FF2D55',
    border: '#e6e6e6',
    shadow: 'rgba(0, 0, 0, 0.1)',
  },
  dark: {
    background: '#1a1a1a',
    text: '#ffffff',
    primary: '#0A84FF',
    secondary: '#5E5CE6',
    accent: '#FF375F',
    border: '#333333',
    shadow: 'rgba(0, 0, 0, 0.3)',
  },
};

export const ThemeProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(false);
  const theme = isDark ? themes.dark : themes.light;

  const contextValue = useMemo(() => ({
    theme,
    isDark,
    toggleTheme: () => setIsDark(!isDark),
  }), [isDark, theme]);

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export default ThemeContext; 